import React from "react";

function BlogSidebar() {
  return (
    <aside className="blog-sidebar">
      <div className="blog-widget">
        <div className="sidebar-search">
          <h4 className="widget-title">Search</h4>
          <form action="#">
            <input id="search" type="text" placeholder="Search" />
            <button type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
      </div>
      <div className="blog-widget">
        <h4 className="widget-title">Categories</h4>
        <div className="categories-list">
          <ul className="list-wrap">
            <li>
              <a href="#">
                Business Consulting <span>(05)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Financial Investment <span>(07)</span>
              </a>
            </li>
            <li>
              <a href="#">
                IT Consultations <span>(03)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Digital Analytics <span>(04)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Payment Processing <span>(02)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Software Solutions <span>(09)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="blog-widget">
        <h4 className="widget-title">Recent News</h4>
        <div className="rc-post-wrap">
          <div className="rc-post-item">
            <div className="rc-post-thumb">
              <a href="/blogs/blog">
                <img
                  src={require(`../../assets/img/blog/rc_post01.jpg`)}
                  alt=""
                />
              </a>
            </div>
            <div className="rc-post-content">
              <h5 className="title">
                <a href="/blogs/blog">
                  Aerial view of a private house with on roof
                </a>
              </h5>
              <span>
                <i className="fas fa-calendar-alt"></i>22 Jan 2023
              </span>
            </div>
          </div>
          <div className="rc-post-item">
            <div className="rc-post-thumb">
              <a href="/blogs/blog">
                <img
                  src={require(`../../assets/img/blog/rc_post02.jpg`)}
                  alt=""
                />
              </a>
            </div>
            <div className="rc-post-content">
              <h5 className="title">
                <a href="/blogs/blog">
                  Medium shot men working on roof presentation
                </a>
              </h5>
              <span>
                <i className="fas fa-calendar-alt"></i>22 Jan 2023
              </span>
            </div>
          </div>
          <div className="rc-post-item">
            <div className="rc-post-thumb">
              <a href="/blogs/blog">
                <img
                  src={require(`../../assets/img/blog/rc_post03.jpg`)}
                  alt=""
                />
              </a>
            </div>
            <div className="rc-post-content">
              <h5 className="title">
                <a href="/blogs/blog">
                  Free photo cute family a roof above their head
                </a>
              </h5>
              <span>
                <i className="fas fa-calendar-alt"></i>22 Jan 2023
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-widget">
        <h4 className="widget-title">Popular Tags</h4>
        <div className="tag-list-wrap">
          <ul className="list-wrap">
            <li>
              <a href="#">Construction</a>
            </li>
            <li>
              <a href="#">Roofing</a>
            </li>
            <li>
              <a href="#">Repairer</a>
            </li>
            <li>
              <a href="#">Maintenance</a>
            </li>
            <li>
              <a href="#">Remodeling</a>
            </li>
            <li>
              <a href="#">Roof</a>
            </li>
            <li>
              <a href="#">Roofing Service</a>
            </li>
            <li>
              <a href="#">Consulting</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="blog-widget widget-bg"
        style={{
          backgroundImage: `url(${require("../../assets/img/blog/widget_bg.jpg")})`,
        }}
      >
        <h4 className="widget-title">Need Help</h4>
        <div className="sidebar-content">
          <h4 className="title">
            Beautiful red brick house with decorative lights
          </h4>
          <p>
            Sit amet consectetur adipiscing elseds do eius mod tempor incididunt
          </p>
          <a href="/contact" className="btn btn-two">
            Contact Us
          </a>
        </div>
      </div>
    </aside>
  );
}

export default BlogSidebar;
