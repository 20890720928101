import React, { useEffect } from "react";
import FooterOne from "../Layouts/Footers/FooterOne";
import HeaderThree from "../Layouts/Headers/HeaderFour";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import BrandAreaWid from "../Common/BrandAreaWid";
import GalleryPage from "./GalleryPage"; // Asegúrate de que esta ruta es correcta

function Gallery() {
  useEffect(() => {
    document.title = "Gallery - D&A Friends Painting LLC";
  }, []);

  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="Gallery"
        paths={[
          { name: "Home", path: "/" },
          { name: "Gallery", path: "/gallery" },
        ]}
      />
      <GalleryPage /> {/* Incluye tu componente de galería aquí */}
      <BrandAreaWid />
      <FooterOne />
    </>
  );
}

export default Gallery;
