import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

function MainWid() {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lx5400c', 'template_nbvllpe', form.current, 'FgTrq606DObPu4cSk')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          setResponseMessage("Message sent successfully!");
          setResponseType("success");
          form.current.reset();  // Reset the form fields
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponseMessage("An error occurred. Please try again later.");
          setResponseType("error");
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        }
      );
  };

  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              <p>Send us a message and we' ll respond as soon as possible</p>
              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        name="user_name"
                        type="text"
                        placeholder="Full Name"
                        required
                      />
                      <label htmlFor="user_name">
                        
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        name="user_email"
                        type="email"
                        placeholder="Email Address"
                        required
                      />
                      <label htmlFor="user_email">
                        
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        name="phone"
                        type="text"
                        placeholder="Phone Number"
                      />
                      <label htmlFor="phone">
                        
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-grp">
                      <input
                        name="address"
                        type="text"
                        placeholder="Address"
                      />
                      <label htmlFor="address">
                        
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-grp">
                  <textarea
                    name="message"
                    placeholder="Your Message here"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn">
                  Send Message
                </button>
                {responseMessage && (
                  <div className={`response-message ${responseType}`}>
                    {responseMessage}
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we'll respond as soon as possible</p>
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:+12033002790">+1 (203) 300-2790</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:friends1painting@gmail.com">friends1painting@gmail.com</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="content">
                      <p>213 Pequonnock St <br /> Bridgeport, CT 06604</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6005.731982335822!2d-73.19889979999999!3d41.181090000000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e80e6f5bb9ef23%3A0xffc882e3c20d343f!2s213%20Pequonnock%20St%2C%20Bridgeport%2C%20CT%2006604!5e0!3m2!1sen!2sus!4v1720527997011!5m2!1sen!2sus"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
