import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

function MultipleArea() {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lx5400c', 'template_nbvllpe', form.current, 'FgTrq606DObPu4cSk')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          setResponseMessage("Message sent successfully!");
          setResponseType("success");
          form.current.reset();  // Reset the form fields
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponseMessage("An error occurred. Please try again later.");
          setResponseType("error");
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        }
      );
  };

  return (
    <div className="area-bg">
      <div
        className="area-background-img jarallax"
        style={{ backgroundImage: `url(${require("../../assets/img/bg/area_bg01.webp")})` }}
      ></div>
      <div className="counter-area-two">
        <div className="container">
          <div className="counter-inner wow fadeInUp" data-wow-delay=".2s">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item-two">
                  <div className="counter-icon">
                    <img
                      src={require(`../../assets/img/icon/counter_icon01.svg`).default}
                      alt=""
                    />
                  </div>
                  <div className="counter-content">
                    <span className="count odometer">1500</span>
                    <p>Project Complete</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item-two">
                  <div className="counter-icon">
                    <img
                      src={require(`../../assets/img/icon/counter_icon02.svg`).default}
                      alt=""
                    />
                  </div>
                  <div className="counter-content">
                    <span className="count odometer">8562</span>
                    <p>Satisfied Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item-two">
                  <div className="counter-icon">
                    <img
                      src={require(`../../assets/img/icon/counter_icon03.svg`).default}
                      alt=""
                    />
                  </div>
                  <div className="counter-content">
                    <span className="count odometer">450</span>
                    <p>Experienced Staff</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="counter-item-two">
                  <div className="counter-icon">
                    <img
                      src={require(`../../assets/img/icon/counter_icon04.svg`).default}
                      alt=""
                    />
                  </div>
                  <div className="counter-content">
                    <span className="count odometer">38</span>
                    <p>Awards Win</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="appointment-area pt-115">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title white-title mb-60">
                <span className="sub-title">
                  Your require professional attention
                </span>
                <h2 className="title">Contact With Us</h2>
              </div>
            </div>
          </div>
          <div
            className="appointment-inner"
            style={{
              backgroundImage: `url(${require("../../assets/img/bg/appointment_bg.jpg")})`,
            }}
          >
            <div className="row">
              <div className="col-xl-7">
                <div className="appointment-form">
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input
                            name="user_name"
                            type="text"
                            placeholder="Full Name"
                            required
                          />
                          <label htmlFor="user_name">
                            <i className="fas fa-user"></i>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input
                            name="user_email"
                            type="email"
                            placeholder="Email Address"
                            required
                          />
                          <label htmlFor="user_email">
                            <i className="fas fa-envelope"></i>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-grp">
                          <input
                            name="phone"
                            type="text"
                            placeholder="Phone Number"
                          />
                          <label htmlFor="phone">
                            <i className="fas fa-phone-alt"></i>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-grp">
                          <input
                            name="address"
                            type="text"
                            placeholder="Address"
                          />
                          <label htmlFor="address">
                            <i className="fas fa-map-marker-alt"></i>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-grp">
                      <textarea
                        name="message"
                        placeholder="Your Message here"
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btn">
                      Send Message
                    </button>
                    {responseMessage && (
                      <div className={`response-message ${responseType}`}>
                        {responseMessage}
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="col-xl-5">
                <div className="appointment-img">
                  <img
                    src={require(`../../assets/img/images/appointment_img.jpg`)}
                    alt=""
                    style={{
                      width: '100%',
                      height: '100%',
                      maxWidth: '482px',
                      maxHeight: '634px',
                      objectFit: 'cover'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MultipleArea;
