import React, { useEffect, useState } from "react";

function HeaderFour() {
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (drawer) {
      document.body.classList.add("mobile-menu-visible");
    } else {
      document.body.classList.remove("mobile-menu-visible");
    }
  }, [drawer]);

  return (
    <header className="sticky-header">
      <div id="sticky-header" className="menu-area menu-area-four transparent-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div onClick={() => setDrawer(!drawer)} className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/logo-5.png`)}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  {/* Desktop Version */}
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li><a href="/home-four">Home</a></li>
                      <li className="menu-item-has-children">
                        <a href="/services">Services</a>
                        <ul className="sub-menu">
                          <li><a href="/services/services-details">Painting Services</a></li>
                          <li><a href="/services/services-details-carpentry">Carpentry Services</a></li>
                          <li><a href="/services/services-details-drywall">Drywall Services</a></li>
                        </ul>
                      </li>
                      <li><a href="/gallery">Gallery</a></li>
                      <li><a href="/about">About us</a></li>
                      <li><a href="/contact">Contact</a></li>
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="header-btn">
                        <a href="/contact" className="btn btn-two">
                          Get a Quote
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Version */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div onClick={() => setDrawer(!drawer)} className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="/">
                      <img
                        src={require(`../../../assets/img/logo/logo-5.png`)}
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="menu-outer">
                    <ul className="navigation">
                      <li><a href="/home-four">Home</a></li>
                      <li className="menu-item-has-children">
                        <a href="/services">Services</a>
                        <ul className="sub-menu">
                          <li><a href="/services/painting-services-details">Painting Services</a></li>
                          <li><a href="/services/services-details-carpentry">Carpentry Services</a></li>
                          <li><a href="/services/services-details-drywall">Drywall Services</a></li>
                        </ul>
                        <div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>
                      </li>
                      <li><a href="/about">Gallery</a></li>
                      <li><a href="/about">About us</a></li>
                      <li><a href="/contact">Contact</a></li>
                    </ul>
                  </div>
                  <div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                      <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                      <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                      <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderFour;
