import React, { useEffect } from "react";
import FooterOne from "../Layouts/Footers/FooterOne";
import HeaderThree from "../Layouts/Headers/HeaderFour";
import InnerPageTitle from "../Helpers/InnerPageTitle";
import AboutArea from "../HomeOne/AboutArea";
import WorkArea from "./WorkArea";
import HistoryArea from "./HistoryArea";
import TestimonialArea from "./TestimonialArea";
import BrandAreaWid from "../Common/BrandAreaWid";

function About() {
  useEffect(() => {
    document.title = "About - D&A Friends Painting LLC";
  }, []);

  return (
    <>
      <HeaderThree />
      <InnerPageTitle
        title="About Us"
        paths={[
          { name: "Home", path: "/" },
          { name: "About", path: "/about" },
        ]}
      />
      <AboutArea className="pt-120" />
      <WorkArea />
      <HistoryArea />
      <div
        className="area-bg-five"
        style={{
          backgroundImage: `url(${require("../../assets/img/bg/area_bg05.jpg")})`,
        }}
      >
        <TestimonialArea className="pt-0" />
      </div>
      <BrandAreaWid />
      <FooterOne />
    </>
  );
}

export default About;
