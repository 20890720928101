import React from "react";
import BlogSidebar from "../BlogSidebar";

function MainWid() {
  return (
    <section className="blog-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="blog-details-wrap">
              <div className="blog-details-thumb">
                <img
                  src={require(`../../../assets/img/blog/blog_details01.jpg`)}
                  alt=""
                />
              </div>
              <div className="blog-details-content">
                <div className="blog-meta">
                  <ul className="list-wrap">
                    <li>
                      <i className="far fa-user"></i> By{" "}
                      <a href="/blogs/blog">Admin</a>
                    </li>
                    <li>
                      <i className="fas fa-calendar-alt"></i>23 Dec 2022
                    </li>
                    <li>
                      <i className="far fa-comments"></i>05
                    </li>
                    <li>
                      <i className="far fa-eye"></i>1,526 VIEWS
                    </li>
                  </ul>
                </div>
                <h2 className="title">
                  Building worker help each other with at construction site
                </h2>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at it as
                  layout. The point of using Lorem Ipsum is that it has a more
                  normal distribution.Collaboratively pontificat bleed aedge
                  resources with inexpensive methodologies globally initiate
                  multidisciplinary compatible architectures as our aspiteously
                  repurpose leading edge growth strategies
                </p>
                <blockquote>
                  <p>
                    Which Podcasts Should Web Designer And Developers Be
                    Listening To Ultimate Digital Clean our Checklist Prepare
                    eadable when content of a page when looking at its layout.
                  </p>
                  <cite>David H. Molina</cite>
                </blockquote>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at it as
                  layout. The point of using Lorem Ipsum is that it has a more
                  normal distribution.
                </p>
                <div className="bd-inner-img-wrap">
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src={require(`../../../assets/img/blog/blog_details02.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="bd-inner-content">
                        <p>
                          The point of using Lorm Ipsum is that it has a our
                          more normal our distribution Collaboratively
                          pontificat bleed edge as resources with inexpensive
                          thje methodologie sglobally initiate
                          multidisciplinary.
                        </p>
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Information
                            Tecaccumulation of technologies
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Autonomous
                            house with its garden and office
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>
                            Self-expression through decor and art
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Regular
                            update monitoring
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Functional
                            minimalism
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Regular
                            update monitoring
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in a our
                  some form, by injected humour, or randomised words which don't
                  look even slightly believable. If you are going our as to use
                  a passage of Lorem Ipsum, you need to be sure there isn't
                  anything embarrassing hidden in the middle the of text. All
                  the Lorem Ipsum generators on the Internet tend.
                </p>
                <div className="blog-details-bottom">
                  <div className="row align-items-center">
                    <div className="col-lg-8">
                      <div className="post-tags">
                        <h5 className="title">Tag:</h5>
                        <ul className="list-wrap">
                          <li>
                            <a href="#">#Construction</a>
                          </li>
                          <li>
                            <a href="#">#Roofing Service</a>
                          </li>
                          <li>
                            <a href="#">#Roofing</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="blog-post-share">
                        <h5 className="title">Share:</h5>
                        <ul className="list-wrap">
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pev-next-post-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <div className="post-item">
                      <div className="post-inner">
                        <div className="thumb">
                          <a href="/blogs/blog">
                            <img
                              src={require(`../../../assets/img/blog/pb_post_img01.jpg`)}
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="content">
                          <h2 className="title">
                            <a href="/blogs/blog">
                              Portrait smiling professional engineer laptop
                            </a>
                          </h2>
                          <span>
                            <i className="far fa-calendar-alt"></i>22 Jan 2023
                          </span>
                        </div>
                      </div>
                      <div className="post-btn">
                        <a href="/blogs/blog">
                          <i className="fas fa-arrow-left"></i>Prev
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="post-item next-post-item">
                      <div className="post-inner">
                        <div className="thumb">
                          <a href="/blogs/blog">
                            <img
                              src={require(`../../../assets/img/blog/pb_post_img02.jpg`)}
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="content">
                          <h2 className="title">
                            <a href="/blogs/blog">
                              Full shot man with helmet sitting on roof
                            </a>
                          </h2>
                          <span>
                            <i className="far fa-calendar-alt"></i>22 Jan 2023
                          </span>
                        </div>
                      </div>
                      <div className="post-btn next-btn">
                        <a href="/blogs/blog">
                          <i className="fas fa-arrow-right"></i>next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="comments-wrap">
                <h4 className="comments-wrap-title">Comments (04)</h4>
                <div className="latest-comments">
                  <ul className="list-wrap">
                    <li>
                      <div className="comments-box">
                        <div className="comments-avatar">
                          <img
                            src={require(`../../../assets/img/blog/comment_avatar01.png`)}
                            alt=""
                          />
                        </div>
                        <div className="comments-text">
                          <div className="avatar-name">
                            <h6 className="name">
                              Abubokkor Siddik
                              <a href="#" className="comment-reply-link">
                                <i className="fas fa-reply"></i>Reply
                              </a>
                            </h6>
                            <span className="date">September 6, 2023</span>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ut tellus
                            suspendisse nulla aliquam. Risus rutrum tellus
                            ultrices.
                          </p>
                        </div>
                      </div>
                      <ul className="list-wrap children">
                        <li>
                          <div className="comments-box">
                            <div className="comments-avatar">
                              <img
                                src={require(`../../../assets/img/blog/comment_avatar02.png`)}
                                alt=""
                              />
                            </div>
                            <div className="comments-text">
                              <div className="avatar-name">
                                <h6 className="name">
                                  Admin
                                  <a href="#" className="comment-reply-link">
                                    <i className="fas fa-reply"></i>Reply
                                  </a>
                                </h6>
                                <span className="date">September 6, 2023</span>
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet consectetur. Ut
                                tellus suspendisse nulla aliquam. Risus rutrum
                                tellus ultrices.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <div className="comments-box">
                        <div className="comments-avatar">
                          <img
                            src={require(`../../../assets/img/blog/comment_avatar03.png`)}
                            alt=""
                          />
                        </div>
                        <div className="comments-text">
                          <div className="avatar-name">
                            <h6 className="name">
                              Elliot Alderson
                              <a href="#" className="comment-reply-link">
                                <i className="fas fa-reply"></i>Reply
                              </a>
                            </h6>
                            <span className="date">September 6, 2023</span>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ut tellus
                            suspendisse nulla aliquam. Risus rutrum tellus.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="comments-box">
                        <div className="comments-avatar">
                          <img
                            src={require(`../../../assets/img/blog/comment_avatar04.png`)}
                            alt=""
                          />
                        </div>
                        <div className="comments-text">
                          <div className="avatar-name">
                            <h6 className="name">
                              Enanath Jhon
                              <a href="#" className="comment-reply-link">
                                <i className="fas fa-reply"></i>Reply
                              </a>
                            </h6>
                            <span className="date">September 6, 2023</span>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet consectetur. Ut tellus
                            suspendisse nulla aliquam. Risus rutrum tellus eget
                            ultrices pretium.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="comment-respond">
                <h3 className="comment-reply-title">Write Your Comment</h3>
                <form className="comment-form" action="#">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-grp">
                        <input type="text" placeholder="Name *" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-grp">
                        <input type="email" placeholder="Email *" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-grp">
                        <input type="url" placeholder="Website*" />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <textarea name="message" placeholder="Comment *"></textarea>
                  </div>
                  <button type="submit" className="btn btn-two">
                    Post Comment
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-10">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
