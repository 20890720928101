import React from "react";

function AboutArea() {
  return (
    <section
      className="about-area-two parallax about-bg"
      style={{
        backgroundImage: `url(${require("../../assets/img/bg/about_bg.jpg")})`,
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="about-img-wrap-two">
              <ul className="list-wrap">
                <li>
                  <div
                    className="year-experience-wrap wow fadeInDown"
                    data-wow-delay=".2s"
                  >
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon03.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <h2 className="title">
                      07 Years <span>Experience</span>
                    </h2>
                  </div>
                </li>
                <li>
                <img
                  src={require(`../../assets/img/images/h2_about_img03.png`)}
                  alt=""
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '311px',
                    maxHeight: '337px',
                    objectFit: 'cover'
                  }}
                />

                </li>
              </ul>
              <ul className="list-wrap">
                <li>
                <img
                  src={require(`../../assets/img/images/h2_about_img02.jpg`)}
                  alt=""
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '311px',
                    maxHeight: '337px',
                    objectFit: 'cover'
                  }}
                />
                </li>
                <li>
                <img
                  src={require(`../../assets/img/images/h2_about_img01.webp`)}
                  alt=""
                  className="wow fadeInLeft"
                  data-wow-delay=".4s"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '373px',
                    maxHeight: '210px',
                    objectFit: 'cover'
                  }}
                />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title mb-30">
                <span className="sub-title">About Our Company</span>
                <h2 className="title">We’re Committed to Excellence</h2>
              </div>
              <p>
              At SD&A Friends Painting LLC, we provide quality painting, carpentry, and drywall services. 
              Our dedicated team ensures exceptional results and customer satisfaction on every project. Trust us with your construction needs.
              </p>
              <div className="about-list-two">
                <ul className="list-wrap">
                  <li className="list-item">
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon01.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">Mission Statement</h5>
                    </div>
                  </li>
                  <li className="list-item">
                    <div className="icon">
                      <img
                        src={
                          require(`../../assets/img/icon/h2_about_icon02.svg`)
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">
                        Our Core Time Values Lenthlyness
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
              <a href="/about" className="btn">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="about-shape-wrap">
        <ul className="list-wrap">
          <li>
            <img
              src={require(`../../assets/img/images/about_shape01.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape02.png`)}
              className="layer"
              data-depth="0.3"
              alt=""
            />
          </li>
          <li>
            <img
              src={require(`../../assets/img/images/about_shape03.png`)}
              className="layer"
              data-depth="0.2"
              alt=""
            />
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AboutArea;
