import React from "react";
import SliderCom from "../Helpers/SliderCom";

function BrandAreaWid({ className }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className={`inner-brand-area pt-120 pb-120 ${className || ""}`}>
      <div className="container">
        <div className="row brand-active-three">
          <SliderCom settings={settings}>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://www.gaf.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img01.png`)}
                    alt="GAF"
                  />
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://www.owenscorning.com/en-us" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img02.png`)}
                    alt="Owens Corning"
                  />
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://www.sherwin-williams.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img03.png`)}
                    alt="Sherwin Williams"
                  />
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://certapro.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img04.png`)}
                    alt="CertaPro"
                  />
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://www.benjaminmoore.com/en-us" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img05.png`)}
                    alt="Benjamin Moore"
                  />
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="brand-item">
                <a href="https://www.certainteed.com/" target="_blank" rel="noopener noreferrer">
                  <img
                    src={require(`../../assets/img/brand/brand_img06.png`)}
                    alt="Benjamin Moore"
                  />
                </a>
              </div>
            </div>
          </SliderCom>
        </div>
      </div>
    </div>
  );
}

export default BrandAreaWid;
