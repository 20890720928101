import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

function MainWid() {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lx5400c', 'template_nbvllpe', form.current, 'FgTrq606DObPu4cSk')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          setResponseMessage("Quote request sent successfully!");
          setResponseType("success");
          form.current.reset();  // Reset the form fields
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponseMessage("An error occurred. Please try again later.");
          setResponseType("error");
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        }
      );
  };

  return (
    <section className="services-details-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img
                  src={require(`../../../assets/img/services/services_details01.jpg`)}
                  alt=""
                />
              </div>
              <div className="services-details-content">
                <h2 className="title">
                Transform Your Home Inside and Out
                </h2>
                <p>
                At D&A Friends Painting LLC, we specialize in both interior and exterior painting services to completely transform your home.
                 Our skilled team ensures a perfect finish inside, enhancing your living spaces with vibrant colors and flawless execution. 
                 Outside, we use durable, weather-resistant paints to protect and beautify your home, increasing curb appeal and longevity. 
                Trust us to handle all your painting needs with professionalism and care, creating a stunning result you'll love.
                </p>
                <div className="services-process-wrap">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                      <div className="services-process-img">
                        <img
                          src={require(`../../../assets/img/services/sp_img01.jpg`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/services/sp_img02.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="services-process-content">
                        <h2 className="title">Our Services</h2>
                        <ul className="list-wrap">
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Interior Painting</h4>
                                <p>
                                Professional interior painting for a fresh look.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon02.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">Exterior Painting</h4>
                                <p>
                                Exterior painting for protection and curb appeal.
                                </p>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="services-process-item">
                              <div className="icon">
                                <img
                                  src={
                                    require(`../../../assets/img/icon/sp_icon01.svg`)
                                      .default
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="content">
                                <h4 className="title">
                                Wallpaper Removal and Installation
                                </h4>
                                <p>
                                Efficient wallpaper removal and new installation.
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h2 className="title-two">
                Interior Painting
                </h2>
                <p>
                At D&A Friends Painting LLC, our interior painting services are designed to breathe new life into your home. We use high-quality paints and expert techniques to ensure a flawless finish that enhances the beauty and ambiance of your living spaces. 
                Whether you want to refresh a single room or transform your entire home, our professional painters are here to deliver stunning results.
                </p>
                <div className="service-benefits-wrap">
                  <div className="row">
                    <div className="col-lg-7 order-0 order-lg-2">
                      <div className="benefits-img">
                        <img
                          src={require(`../../../assets/img/services/sb_img01.webp`)}
                          alt=""
                        />
                        <img
                          src={require(`../../../assets/img/services/sb_img02.jpg`)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="benefits-content">
                        <h2 className="title">Exterior Painting</h2>
                        <p>
                        Our exterior painting services at D&A Friends Painting LLC are tailored to protect and beautify your home’s exterior. We use durable, weather-resistant paints that withstand the elements while adding curb appeal. 
                        Our experienced team ensures meticulous preparation and application, resulting in a long-lasting, vibrant finish that enhances your home’s value.
                        </p>
                        <ul className="list-wrap">
                          <li>
                            <i className="fas fa-check-circle"></i>Detailed color consultation
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Precision painting
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Eco-friendly, low-VOC paints
                          </li>
                          <li>
                            <i className="fas fa-check-circle"></i>Minimal disruption and clean-up
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-6">
            <aside className="services-sidebar">
              <div className="services-widget">
                <h4 className="widget-title">Our All Service</h4>
                <div className="our-services-list">
                  <ul className="list-wrap">
                    <li>
                      <a href="/services/services-details">
                        Interior Painting
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Exterior Painting
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Staining and Finishing
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details">
                        Wallpaper Removal and Installation
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details-carpentry">
                        Carpentry Services
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/services/services-details-drywall">
                        Drywall Services
                        <i className="fas fa-arrow-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="services-widget widget-bg"
                style={{
                  backgroundImage: `url(${require('../../../assets/img/services/sw_bg.jpg')})`
                }}
              >
                <h4 className="widget-title">Get a free quote</h4>
                <form ref={form} onSubmit={sendEmail} className="sidebar-form">
                  <div className="form-grp">
                    <input name="user_name" type="text" placeholder="Your Name" required />
                  </div>
                  <div className="form-grp">
                    <input name="user_email" type="email" placeholder="Your Email Address" required />
                  </div>
                  <div className="form-grp">
                    <textarea name="message" placeholder="Your Message" required></textarea>
                  </div>
                  <button type="submit" className="btn btn-two">Contact Us</button>
                  {responseMessage && (
                    <div className={`response-message ${responseType}`}>
                      {responseMessage}
                    </div>
                  )}
                </form>
              </div>
              
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
