import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';

function FooterOne() {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_lx5400c', 'template_j3m8j6a', form.current, 'FgTrq606DObPu4cSk')
      .then(
        (result) => {
          console.log('SUCCESS!', result.status, result.text);
          setResponseMessage("Subscription successful!");
          setResponseType("success");
          form.current.reset();  // Reset the form fields
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setResponseMessage("An error occurred. Please try again later.");
          setResponseType("error");
          setTimeout(() => {
            setResponseMessage("");
          }, 2000);
        }
      );
  };

  return (
    <footer>
      <div
        className="footer-area footer-bg"
        style={{
          backgroundImage: `url(${require("../../../assets/img/bg/footer_bg.jpg")})`,
        }}>
        <div className="footer-top" style={{ marginBottom: '10px' }}> {/* Ajusta este valor según sea necesario */}
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-7">
                <div className="footer-widget">
                  <h2 className="fw-title">About Us</h2>
                  <div className="footer-content">
                    <p>
                      At D&A Friends Painting LLC in New Haven, we provide top-quality painting, 
                      carpentry, and drywall services. With over 10 years of experience, we 
                      guarantee exceptional results and customer satisfaction.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12">
                    <div className="logo">
                      <a href="/">
                        <img
                          src={require(`../../../assets/img/logo/w_logo.png`)}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Our Services</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <ul>
                        <li><a href="/project"><i className="fas fa-angle-double-right"></i>Painting Services</a></li>
                        <ul>
                          <li><a href="/project"><i className="fas fa-angle-right"></i>Interior Painting</a></li>
                          <li><a href="/project"><i className="fas fa-angle-right"></i>Exterior Painting</a></li>
                          <li><a href="/project"><i className="fas fa-angle-right"></i>Staining and Finishing</a></li>
                          <li><a href="/project"><i className="fas fa-angle-right"></i>Wallpaper Removal and Installation</a></li>
                        </ul>
                        <li><a href="/project"><i className="fas fa-angle-double-right"></i>Carpentry Services</a></li>
                        <li><a href="/project"><i className="fas fa-angle-double-right"></i>Drywall Services</a></li>
                      </ul>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h2 className="fw-title">Quick Links</h2>
                  <div className="footer-link">
                    <ul className="list-wrap">
                      <li><a href="/home-four"><i className="fas fa-angle-double-right"></i>Home</a></li>
                      <li><a href="/services"><i className="fas fa-angle-double-right"></i>Services</a></li>
                      <li><a href="/gallery"><i className="fas fa-angle-double-right"></i>Gallery</a></li>
                      <li><a href="/about"><i className="fas fa-angle-double-right"></i>About us</a></li>
                      <li><a href="/contact"><i className="fas fa-angle-double-right"></i>Contact</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6">
                <div className="footer-widget">
                  <div className="footer-newsletter">
                    <h2 className="fw-title">Subscribe to Our Newsletter</h2>
                    <form ref={form} onSubmit={sendEmail}>
                      <input name="user_email" type="email" placeholder="Enter your email" required />
                      <button type="submit" className="btn btn-two">Subscribe</button>
                      {responseMessage && (
                        <div className={`response-message ${responseType}`}>
                          {responseMessage}
                        </div>
                      )}
                    </form>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <div className="footer-contact" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f00', borderRadius: '8px', padding: '10px', width: '80%', maxWidth: '600px' }}>
                      <div className="icon" style={{ padding: '10px', backgroundColor: '#fff', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <i className="fas fa-phone-alt" style={{ color: '#f00', fontSize: '24px' }}></i>
                      </div>
                      <div className="content" style={{ marginLeft: '10px' }}>
                        <span style={{ display: 'block', color: '#fff', fontWeight: 'bold', fontSize: '18px' }}>Phone No</span>
                        <a href="tel:+12033002790" style={{ display: 'block', color: '#fff', textDecoration: 'none', fontSize: '15px' }}>+1 (203) 300-2790</a>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                    <div className="footer-social" style={{ display: 'flex', alignItems: 'center' }}>
                      <h2 className="title" style={{ margin: 0, marginRight: '10px', fontWeight: 'bold', color: '#fff', fontSize: '24px' }}>Follow Us:</h2>
                      <a href="https://www.facebook.com/profile.php?id=100095716115196" style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '40px', height: '40px', backgroundColor: '#fff', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <i className="fab fa-facebook-f" style={{ color: '#f00', fontSize: '24px' }}></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="footer-bottom" style={{ marginTop: '-90px' }}> {/* Ajusta este valor según sea necesario */}
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p>© Copyright 2023. All Right Reserved</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-bootom-menu">
                  <ul className="list-wrap">
                    <li>
                      <a href="/contact">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/contact">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterOne;
